import React from 'react';
import Layout from '../components/Layout';
const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <div className="wrapper">
        <header>
          <div className="inner">
            <h2>Basic Packing</h2>
            <p>
              Included with the basic package of all of our moving companies is
              a basic packing service.
            </p>
          </div>
        </header>
        <div className="inner">
          <section id="two" className="wrapper alt spotlight style2">
            <h3 className="major">All the blankets and pads</h3>
            <p>
              Every sinlge item which goes on a moving truck has to be wrapped
              up in a moving blanket. That's a lot of blankets. And all those
              blankets would equal a whole lot of dollars. Dollars you'd
              probably rather spend on coffee. Or skydiving. Or...Anything other
              than moving blankets. So we send all the blankets.
            </p>
            <div className="included-list-wrapper">
              <ul>
                <li>
                  {' '}
                  The movers dissassemble all the items which need to be taken
                  apart
                </li>
                <li>
                  The movers tag and bag every set of nuts, bolts, washers and
                  screws
                </li>
                <li>
                  {' '}
                  The movers wrap everything in moving blankets and pads before
                  loading them onto your moving truck
                </li>
                <li>
                  {' '}
                  The movers check if there are any packing materials you need,
                  or if there are any items you need to have specially packed{' '}
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);
export default IndexPage;
